import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 更新头像
 * @param {Object} params
 */
export function updateAvatar(params) {
  return request({
    url: envInfo.bgApp.ssoPath + '/sso/user/update/avatar',
    method: 'post',
    data: params,
  });
}

/**
 * 更新个人资料
 * @param {Object} params
 */
export function updateProfile(params) {
  return request({
    url: envInfo.bgApp.ssoPath + '/sso/user/updateGenPersonal',
    method: 'post',
    data: params,
  });
}

/**
 * 获取用户资料
 * @param {*} params
 */
export function findProfile(params) {
  return request({
    url: envInfo.bgApp.ssoPath + '/sso/user/findUserInformation',
    method: 'get',
    params,
  });
}

/**
 * 发送更新手机号的验证码
 * @param {Object} params
 */
export function sendUptCaptcha(params) {
  return request({
    url: envInfo.bgApp.ssoPath + '/sso/user/sendPhoneUptCaptcha',
    method: 'post',
    data: params,
  });
}

/**
 * 更新手机号
 * @param {Object} params
 */
export function updatePhone(params) {
  return request({
    url: envInfo.bgApp.ssoPath + '/sso/user/updatePhone',
    method: 'post',
    data: params,
  });
}

/**
 * 获取验证码
 * @param {Object} params
 */
export function getCaptchaPicture() {
  return request({
    url: envInfo.bgApp.ssoPath + '/servlet/captcha?v=' + new Date().getTime(),
    method: 'get',
  });
}

/**
 * 查询手机区号
 * @param params
 */
export function findPhoneZoneCode(params) {
  return request({
    url:
      envInfo.bgApp.lookupPath +
      `/lookup/item/find/list?classifyCode=${params.classifyCode}`,
    method: 'get',
  });
}

/**
 * 修改密码
 * @param {Object} params
 */
export function updatePwd(params) {
  return request({
    url: envInfo.bgApp.ssoPath + '/sso/user/changePwd',
    method: 'post',
    data: params,
  });
}
