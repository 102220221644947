var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "park-notice-dialog" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "公告标题", prop: "noticeTitle" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "50",
                          placeholder: "请输入公告标题",
                          disabled: _vm.currentState === "view",
                        },
                        model: {
                          value: _vm.ruleForm.noticeTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "noticeTitle", $$v)
                          },
                          expression: "ruleForm.noticeTitle",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发布时间", prop: "pubdate" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: _vm.currentState === "view",
                          type: "datetime",
                          "value-format": "timestamp",
                          "picker-options": _vm.pickerOptions,
                          placeholder: "请选择发布时间",
                        },
                        model: {
                          value: _vm.ruleForm.pubdate,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "pubdate", $$v)
                          },
                          expression: "ruleForm.pubdate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "展示位置", prop: "showAddress" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            disabled: _vm.currentState === "view",
                            placeholder: "请选择展示位置",
                          },
                          model: {
                            value: _vm.ruleForm.showAddress,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "showAddress", $$v)
                            },
                            expression: "ruleForm.showAddress",
                          },
                        },
                        _vm._l(_vm.showAddressList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发布人", prop: "publisher" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.ruleForm.publisher,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "publisher", $$v)
                          },
                          expression: "ruleForm.publisher",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "公告类型", prop: "noticeType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            disabled: _vm.currentState === "view",
                            placeholder: "请选择公告类型",
                          },
                          model: {
                            value: _vm.ruleForm.noticeType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "noticeType", $$v)
                            },
                            expression: "ruleForm.noticeType",
                          },
                        },
                        _vm._l(_vm.noticeTypeList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "链接地址", prop: "linkAddress" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.currentState === "view" },
                    model: {
                      value: _vm.ruleForm.linkAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "linkAddress", $$v)
                      },
                      expression: "ruleForm.linkAddress",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.currentState === "edit" || _vm.currentState === "add"
            ? _c(
                "el-form-item",
                { attrs: { align: "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetForm()
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.currentState === "view"
            ? _c(
                "el-form-item",
                { attrs: { align: "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.changeStateToEdit },
                    },
                    [_vm._v("编辑")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }